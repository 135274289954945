<script>
import axios from "axios";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { VueEditor, Quill  } from "vue2-editor";
import { authHeader } from "../../../helpers/auth-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";
import SaveButton from "@/components/buttons/save-button";

export default {
    components: {
        Layout,
        PageHeader,
        ConfirmationModal,
        MessageModal,
        SaveButton,
        VueEditor
    },
    data() {
        return {
            title: 'Terms & Conditions',
            items: [
                {
                    text: "Settings"
                },
            ],
            editorContent: ''
        };
    },
    async created() {
        const result = await axios.get(`${process.env.VUE_APP_APIURL}/general`, {
            headers: authHeader(),
        });
        
        if (result.status === 200) {
            this.editorContent = result.data.content;
        }
    },
    async mounted() {
        var Block = Quill.import('blots/block');
        console.log(Block);
    },
    methods: {
        async save() {
            console.log(this.inputVal);
            const result = await axios.put(`${process.env.VUE_APP_APIURL}/general`, {
                "content": this.editorContent,
            }, {
                headers: authHeader(),
            });

            if (result.data.code === 'success') {
                this.$refs.messageModal.showModal('Your record has been updated successfully');
            }
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" />
        <div class="row">
            <div class="col-lg-12">
                <div>
                    <b-tabs content-class="" >
                        <b-tab title="Terms & Conditions" active>
                            <div class="card">
                                <div class="card-body tab-content">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <VueEditor v-model="editorContent"/>
                                                </div>
                                            </div>
                                            <SaveButton @click="save"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
    </Layout>
</template>